.loginRight {
    padding: 50px;
    background-size: cover;
    background-repeat: no-repeat !important;
    display: flex;
    border-left: 1px solid #E9E9E9;
    background-size: cover;
    background-position: bottom right !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media only screen and (max-width:768px) {
        padding: 15px 25px;
    }
}

.description {
    color: #171B1F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: 0.1px;
    margin: 20px 0 20px;
    text-align: left;
    max-width: 450px;
}

.header {
    color: #171B1F;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: left;
}

.storesWrap {

    margin: 30px 0 !important;
    gap: 10px;


    @media only screen and (max-width:768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    img {
        height: 48px;
        // width: 108px;
    }
}

.registerButton {
    height: 48px;
    padding: 0 30px !important;
    margin: 20px 0;
}