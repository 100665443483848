.loginPage {
    display: flex;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    background-color: #F3F6F6;
    gap: 0px !important;
    height: 100%;

    @media (max-width: 992px) {
        display: block;
        flex-direction: column-reverse;
        aspect-ratio: unset;
        height: 100vh;
        overflow: auto;
    }

    .loginLeft {
        background-size: cover;
        background-position: bottom left !important;
        background-repeat: no-repeat !important;
        padding: 50px 100px;
        overflow: auto;

        @media (max-width: 992px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 50%;
            padding: 15px 25px;
        }
    }

    .loginForm {
        width: 100%;
        max-width: 480px;

        .selectPicker {
            height: 48px;
            width: 100%;
            margin: 10px 0;
        }
    }


}

.rightPart {
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: right !important;

    @media (max-width: 992px) {
        height: 35vh;
    }

    .storesWrap {
        position: absolute;
        bottom: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 15;

        @media (max-width: 768px) {
            flex-direction: row;
            align-items: center;
            gap: 20px;
        }

        img {
            height: 48px;
            margin-right: 10px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(0deg, rgba(47, 178, 140, 0.50) 0%, rgba(8, 17, 15, 0.00) 100%) !important;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
}

.description {
    color: #171B1F;
    font-size: 14px;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: 0.1px;
    margin: 20px 0;
    text-align: left;
    max-width: 450px;
}

.header {
    color: #171B1F;
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: left;
}

.modalContent {
    width: 100%;

}

.inputClass {
    height: 48px !important;

    &.invalid {
        border: 1px solid #e2184a;
    }
}

.submitButton {
    height: 48px;
    width: 100%;
    margin-top: 20px;
}


:global {

    .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
    .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
        top: 14px;
    }

    .rs-picker-select-menu-item {
        color: #00A27F;
    }

    .rs-picker-select-menu-item,
    .rs-picker-select-menu-item-active,
    .rs-picker-select-menu-item-focus {
        color: #00A27F !important;
    }
}

.forgotPasswordLink {
    display: block;
    margin: 20px 0;
    color: #00A27F;
    font-weight: 700;

    &:hover {
        color: #00A27F;
        text-decoration: underline;
    }
}

.errorOccurred {
    color: #e2184a;
    font-weight: 700;
}

.stepWrap {
    height: 3px;
    display: flex;
    gap: 10px;
    margin: 10px 0 0;

    .step {
        height: 3px;
        width: 100px;
        border-radius: 5px;
    }
}